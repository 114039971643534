import { Injectable } from '@angular/core';
import { Subject, map } from 'rxjs';
import { ApiService } from './api.service';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public currentUserSubject = new Subject<User | null>();
  currentUser = this.currentUserSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getUsers(params?: {[key: string]: any}) {
    return this.apiService.get(`users`, params);
  }

  getUser(userId: number, params?: {[key: string]: any}) {
    return this.apiService.get(`users/${userId}`, params);
  }

  createUser(params?: {[key: string]: string}) {
    return this.apiService.post(`users`, params);
  }

  updateUser(userId: number, params?: {[key: string]: any}) {
    return this.apiService.put(`users/${userId}`, params);
  }

  updateUserPassword(userId: number, params?: {[key: string]: any}) {
    return this.apiService.put(`auth/users/${userId}/change-password`, params);
  }

  deleteUser(userId: number) {
    return this.apiService.delete(`users/${userId}`);
  }

  getCurrentUser(isObservableChange: boolean = true) {
    return this.apiService.get('auth/profile')
      .pipe(
        map(
          (response) => {
            if(isObservableChange) { this.currentUserSubject.next(response); }
            return response;
          }
        )
      );
  }

}
